<script setup lang="ts">
import type { DashboardSidebarLink } from '#ui-pro/types';

const appConfig = useAppConfig()
const { user } = useUserSession()
const { modules, moduleId } = useModules()

const $tasks = await useFetch<Task[]>('/db/tasks', {
  key: `tasks-${user.value!.id}`, // syncs with $tasks in /tasks page (on CRUD)
  query: { userId: user.value!.id },
  default: () => [],
})

// const $usernotifications = await useFetch<Notification[]>('/db/usernotifications', {
//   // key: `usernotifications-${user.value!.id}`, // syncs with $usernotifications in /notifications page (on CRUD)
//   query: { userId: user.value!.id, $with: { notification: true } },
//   default: () => [],
// })

const {
  // moduleId,
  coreModules,
  otherModules,
  activeModule,
  links,
  items,
  isModuleActive,
} = useNavigation(modules)

// DISABLED: UDashboardSearchButton and LazyUDashboardSearch disabled until v1 ready
// const groups = computed(() => [
//   {
//     key: 'links',
//     label: 'Módulos',
//     commands: links.value.flat().map(link => ({
//       ...link,
//       shortcuts: link.tooltip?.shortcuts,
//     })),
//   },
// ])

const footerLinks = computed<DashboardSidebarLink[]>(() => [
  {
    label: 'Tareas',
    icon: 'i-mdi-checkbox-marked-outline',
    to: '/tasks',
    tooltip: {
      text: 'Tareas',
      shortcuts: ['G', 'T'],
    },
    ...($tasks.data.value?.length && {
      badge: {
        label: String($tasks.data.value.length),
        color: 'red',
        size: 'xs',
      },
    }),
  },
  // {
  //   label: 'Notificaciones',
  //   icon: 'i-mdi-bell',
  //   to: '/notifications',
  //   tooltip: {
  //     text: 'Notificaciones',
  //     shortcuts: ['G', 'N'],
  //   },
  //   ...($usernotifications.data.value?.length && {
  //     badge: {
  //       label: String($usernotifications.data.value.length),
  //       color: 'red',
  //       size: 'xs',
  //     },
  //   }),
  // },
])
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 250, max: 350, storage: 'local' }"
      collapsible
    >
      <UDashboardNavbar
        class="!border-transparent"
        :ui="{ left: 'flex-1 items-center' }"
      >
        <template #left>
          <UButton
            to="/"
            variant="ghost"
            square
            class="grid"
          >
            <UAvatar
              src="/favicon.svg"
              title="Inicio"
            />
          </UButton>
          <h2
            v-if="!isModuleActive"
            class="truncate text-gray-900 dark:text-white text-[16px] font-semibold"
          >
            {{ appConfig.netzo.title }}
          </h2>
          <ModulesDropdown v-else />
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <template #header>
          <!-- <UDashboardSearchButton label="Buscar..." /> -->
          <div id="teleport-dashboard-sidebar-header" />
        </template>

        <template
          v-for="(linksGroup, i) in links"
          :key="`group-${i}`"
        >
          <UDashboardSidebarLinks
            :links="linksGroup"
            :ui="{
              active: 'before:bg-primary-100 dark:before:bg-primary-800',
              inactive: 'hover:before:bg-primary-50 dark:hover:before:bg-primary-800/50',
            }"
          >
            <template #divider>
              <UDivider />
            </template>
          </UDashboardSidebarLinks>
          <UDivider v-if="i < links.length - 1" />
        </template>

        <div class="flex-1" />

        <UDashboardSidebarLinks
          :links="footerLinks"
          :ui="{
            active: 'before:bg-primary-100 dark:before:bg-primary-800',
            inactive: 'hover:before:bg-primary-50 dark:hover:before:bg-primary-800/50',

          }"
        />

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <UserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />

    <UsernotificationsSlideoverList />

    <!-- DISABLED: <ClientOnly>
      <LazyUDashboardSearch
        :groups="groups"
        :empty-state="{ icon: 'i-mdi-magnify', label: 'No se encontraron resultados.', queryLabel: 'No se encontraron resultados para esa busqueda. Intentalo de nuevo.' }"
        placeholder="Buscar..."
      />
    </ClientOnly> -->
  </UDashboardLayout>
</template>

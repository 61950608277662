<script setup lang="ts">
const { isDashboardSearchModalOpen } = useUIState()
// const { metaSymbol } = useShortcuts()

const { clear, user } = useUserSession()

const signOut = async () => {
  await clear()
  return navigateTo('/auth/login')
}

const items = computed(() => [
  [
    {
      slot: 'account',
      label: '',
      disabled: true,
    },
  ],
  [
    {
      label: 'Ajustes',
      icon: 'i-mdi-cog',
      to: '/settings',
    },
  ],
  [
    {
      label: 'Cerrar sesión',
      icon: 'i-mdi-logout',
      click: signOut,
    },
  ],
])
</script>

<template>
  <UDropdown
    mode="hover"
    :items="items"
    :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        :label="user?.name"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      >
        <template #leading>
          <UAvatar
            :src="user?.image"
            :alt="user?.name"
            size="2xs"
          />
        </template>

        <template #trailing>
          <UIcon
            name="i-mdi-dots-horizontal"
            class="w-5 h-5 ml-auto"
          />
        </template>
      </UButton>
    </template>

    <template #account>
      <UAvatar
        :src="user?.image"
        :alt="user?.name"
        size="xs"
        class="mr-1"
      />
      <div class="text-left">
        <p>
          {{ user?.name ?? 'Sesión iniciada como' }}
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white text-xs">
          {{ user?.email }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>

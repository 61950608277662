import type { DashboardSidebarLink } from '#ui-pro/types'
import type { DropdownItem } from '#ui/types'
import { tableIconsMap } from '~/utils'

const moduleToDashboardSidebarLink = (mod: Module): DashboardSidebarLink => ({
  label: mod.name,
  icon: mod.icon,
  to: mod.disabled ? undefined : `/${mod.id}`,
  tooltip: {
    text: mod.name,
    // shortcuts: [],
  },
  disabled: mod.disabled,
})

const moduleToDropdownItem = (mod: Module): DropdownItem => ({
  label: mod.name,
  to: mod.disabled ? undefined : `/${mod.id}`,
  icon: mod.icon,
  disabled: mod.disabled,
})

export function useNavigation(modules: ComputedRef<Module[]>) {
  const route = useRoute()

  const moduleId = computed({
    get: () => route.path.split('/')[1] ?? 'admin',
    set: (value: string) => navigateTo(`/${value}`),
  })

  const CORE = ['admin']
  const coreModules = computed<Module[]>(() => modules.value.filter(mod => CORE.includes(mod.id)))
  const otherModules = computed<Module[]>(() => modules.value.filter(mod => !CORE.includes(mod.id)))

  const activeModule = computed(() => modules.value.find(mod => mod.id === moduleId.value))

  const LINKS = computed<Record<string, DashboardSidebarLink[]>>(() => ({
    management: [
      {
        label: 'Dashboards',
        icon: 'i-mdi-view-dashboard',
        children: [
          {
            label: 'Ventas',
            to: '/management/dashboards/sales',
            tooltip: { text: 'Dashboard ventas' },
          },
          {
            label: 'Finanzas',
            tooltip: { text: 'Finanzas' },
            disabled: true,
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Dashboards' },
      },
      {
        label: 'Directorio',
        icon: 'i-mdi-folder-account',
        children: [
          {
            label: 'Cuentas',
            to: '/management/accounts',
            tooltip: { text: 'Cuentas' },
          },
          {
            label: 'Contactos',
            to: '/management/contacts',
            tooltip: { text: 'Contactos' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Directorio' },
      },
    ],
    purchases: [
      {
        label: 'Consultas',
        icon: tableIconsMap.inquiries,
        to: '/purchases/inquiries',
        tooltip: { text: 'Consultas' },
      },
      {
        label: 'Directorio',
        icon: 'i-mdi-folder-account',
        children: [
          {
            label: 'Proveedores',
            to: '/purchases/suppliers',
            tooltip: { text: 'Proveedores' },
          },
          {
            label: 'Contactos',
            to: '/purchases/contacts',
            tooltip: { text: 'Contactos' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Directorio' },
      },
    ],
    sales: [
      {
        label: 'Negocios',
        icon: tableIconsMap.deals,
        to: '/sales/deals',
        tooltip: { text: 'Negocios' },
      },
      {
        label: 'Actividades',
        icon: tableIconsMap.activities,
        to: '/sales/activities',
        tooltip: { text: 'Actividades' },
      },
      {
        label: 'Consultas',
        icon: tableIconsMap.inquiries,
        to: '/sales/inquiries',
        tooltip: { text: 'Consultas' },
      },
      {
        label: 'Campañas',
        icon: tableIconsMap.campaigns,
        to: '/sales/campaigns',
        tooltip: { text: 'Campañas' },
      },
      {
        label: 'Directorio',
        icon: 'i-mdi-folder-account',
        children: [
          {
            label: 'Clientes',
            to: '/sales/customers',
            tooltip: { text: 'Clientes' },
          },
          {
            label: 'Contactos',
            to: '/sales/contacts',
            tooltip: { text: 'Contactos' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Directorio' },
      },
      {
        label: 'Cotizaciones',
        icon: tableIconsMap.quotes,
        to: '/sales/quotes',
        tooltip: { text: 'Cotizaciones' },
      },
      {
        label: 'Ordenes de Compra',
        icon: tableIconsMap.purchaseorders,
        to: '/sales/purchaseorders',
        tooltip: { text: 'Ordenes de Compra' },
      },
      {
        label: 'Ordenes de Trabajo',
        icon: tableIconsMap.workorders,
        to: '/sales/workorders',
        tooltip: { text: 'Ordenes de Trabajo' },
      },
      {
        label: 'Industrias',
        icon: tableIconsMap.industries,
        to: '/sales/industries',
        tooltip: { text: 'Industrias' },
      },
      {
        label: 'Productos',
        icon: tableIconsMap.products,
        children: [
          {
            label: 'Categorias',
            to: '/sales/categories?type=products',
            exactQuery: true,
            tooltip: { text: 'Categorias' },
          },
          {
            label: 'Aplicaciones',
            to: '/sales/applications',
            tooltip: { text: 'Aplicaciones' },
          },
          {
            label: 'Productos',
            to: '/sales/products',
            tooltip: { text: 'Productos' },
          },
          {
            label: 'Presentaciones',
            to: '/sales/productitems',
            tooltip: { text: 'Presentaciones' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Products' },
      },
      {
        label: 'Servicios',
        icon: tableIconsMap.services,
        children: [
          {
            label: 'Categorias',
            to: '/sales/categories?type=services',
            exactQuery: true,
            tooltip: { text: 'Categorias' },
          },
          {
            label: 'Servicios',
            to: '/sales/services',
            tooltip: { text: 'Servicios' },
          },
          {
            label: 'Modalidades',
            to: '/sales/serviceitems',
            tooltip: { text: 'Modalidades' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Servicios' },
      },
      {
        label: 'Precios',
        icon: tableIconsMap.pricelists,
        // to: '/sales/pricelists',
        tooltip: { text: 'Catalog Precios' },
      },
    ],
    finances: [
      {
        label: 'Informes',
        icon: 'i-mdi-chart-line',
        children: [
          {
            label: 'Flujo de caja',
            // to: '/finances/reports/cashflow',
            tooltip: { text: 'Informes de flujo de caja' },
          },
          {
            label: 'Estado de resultados',
            // to: '/finances/reports/income-statement',
            tooltip: { text: 'Informes de estado de resultados' },
          },
          {
            label: 'Balance General',
            // to: '/finances/reports/balance-sheet',
            tooltip: { text: 'Informes de balance general' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Informes Financieros' },
      },
      {
        label: 'Tesorería',
        icon: 'i-mdi-cash-register',
        children: [
          {
            label: 'Conciliaciones',
            to: '/finances/reconciliations',
            tooltip: { text: 'Conciliaciones' },
          },
          {
            label: 'Cuentas por cobrar',
            tooltip: { text: 'Cuentas por cobrar' },
            disabled: true,
          },
          {
            label: 'Cuentas por pagar',
            tooltip: { text: 'Cuentas por pagar' },
            disabled: true,
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Tesorería' },
      },
      {
        label: 'Bancos y Cuentas',
        icon: 'i-mdi-bank-outline',
        children: [
          {
            label: 'Bancos',
            to: '/finances/banks',
            tooltip: { text: 'Bancos' },
          },
          {
            label: 'Cuentas financieras',
            to: '/finances/financialaccounts',
            tooltip: { text: 'Cuentas y tesorerías financieras' },
          },
          {
            label: 'Transacciones',
            to: '/finances/transactions',
            tooltip: { text: 'Transacciones' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Bancos y Cuenta' },
      },
      {
        label: 'Documentos',
        icon: 'i-mdi-file-document',
        children: [
          {
            label: 'Facturas sin timbrar',
            // to: '/finances/cfdis',
            tooltip: { text: 'Facturas sin timbrar' },
          },
          {
            label: 'CFDIs',
            to: '/finances/cfdis',
            tooltip: { text: 'CFDIs' },
          },
          {
            label: 'Cargar CFDIs',
            to: '/finances/upload',
            tooltip: { text: 'Cargar CFDIs' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Documentos' },
      },
      {
        label: 'Directorio',
        icon: 'i-mdi-folder-account',
        children: [
          {
            label: 'Cuentas',
            to: '/finances/accounts',
            tooltip: { text: 'Cuentas' },
          },
          {
            label: 'Contactos',
            to: '/finances/contacts',
            tooltip: { text: 'Contactos' },
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Directorio' },
      },
    ],
    inventory: [
      // {
      //   label: 'Categorías',
      //   icon: tableIconsMap.categories,
      //   to: '/inventory/categories',
      //   tooltip: { text: 'Categorías' },
      // },
      // {
      //   label: 'Productos',
      //   icon: tableIconsMap.products,
      //   to: '/inventory/products',
      //   tooltip: { text: 'Pedidos' },
      // },
      // {
      //   label: 'Presentaciones',
      //   icon: tableIconsMap.productitems,
      //   to: '/inventory/productitems',
      //   tooltip: { text: 'Presentaciones' },
      // },
    ],
    admin: [
      {
        label: 'Autenticación',
        icon: 'i-mdi-security',
        children: [
          {
            label: 'Usuarios',
            to: '/admin/auth/users',
            tooltip: { text: 'Usuarios' },
          },
        ],
        defaultOpen: true,
        tooltip: {
          text: 'Autenticación',
        },
      },
      {
        label: 'Almacenamiento',
        icon: 'i-mdi-content-save',
        children: [
          {
            label: 'DB',
            to: '/admin/storage/db',
            tooltip: { text: 'DB' },
          },
          {
            label: 'KV',
            to: '/admin/storage/kv',
            tooltip: { text: 'KV' },
          },
          {
            label: 'Blob',
            to: '/admin/storage/blob',
            tooltip: { text: 'Blob' },
          },
        ],
        defaultOpen: true,
        tooltip: {
          text: 'Almacenamiento',
        },
      },
      {
        label: 'Registros de Auditoría',
        icon: tableIconsMap.auditlogs,
        to: '/admin/auditlogs',
        tooltip: { text: 'Registros de Auditoría' },
      },
    ],
  }))

  const links = computed<DashboardSidebarLink[][]>(() => {
    if (moduleId.value in LINKS.value) return [LINKS.value[moduleId.value]]
    return [
      otherModules.value.map(moduleToDashboardSidebarLink), // .sort(sortBy('label')),
      coreModules.value.map(moduleToDashboardSidebarLink),
    ]
  })

  const items = computed<DropdownItem[][]>(() => {
    return [
      otherModules.value.map(moduleToDropdownItem), // .sort(sortBy('label')),
      coreModules.value.map(moduleToDropdownItem),
    ]
  })

  const isModuleActive = computed(() => modules.value.some(mod => route.path.startsWith(`/${mod.id}`)))

  return {
    moduleId,
    coreModules,
    otherModules,
    activeModule,
    links,
    items,
    isModuleActive,
  }
}
